<template>
  <div>
    <section id="knowledge-base-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{
          height: `40vh`,
          backgroundImage: `url(${require('@/assets/images/banner/banner1.png')})`,
        }"
      >
        <b-card-body class="card-body">
          <h2 class="text-primary">
            Mijas Servicios Complementarios
          </h2>
          <b-card-text class="mb-2">
            <span
              class="font-weight-bolder"
            >Una empresa municipal cuyo objeto social es la integración social
              y laboral de las<br>
              personas con especiales dificultades de acceso al mercado laboral.
            </span>
          </b-card-text>
        </b-card-body>
      </b-card>
    </section>

    <section id="knowledge-base-content">
      <!-- content -->
      <b-row>
        <b-col
          cols="12"
          lg="4"
          md="4"
          sm="6"
        >
          <BlockCard
            button="Ver más"
            description="Crear documentos con campos personalizados para los empleados"
            title="Diseño Docs"
            icon="EditIcon"
            @action="$router.push({ name: 'docs-new' })"
          />
        </b-col>

        <b-col
          cols="12"
          lg="4"
          md="4"
          sm="6"
        >
          <BlockCard
            button="Ver más"
            description="Imprimir documentos creados previamente en la plataforma"
            title="Imprimir Docs"
            icon="PrinterIcon"
            @action="$router.push({ name: 'docs-list' })"
          />
        </b-col>

        <b-col
          cols="12"
          lg="4"
          md="4"
          sm="6"
        >
          <BlockCard
            button="Ver más"
            description="Gestión de los empleados registrados en la plataforma"
            title="Empleados"
            icon="UsersIcon"
            @action="$router.push({ name: 'empleados-list' })"
          />
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
} from 'bootstrap-vue'
import BlockCard from '@/components/BlockCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BlockCard,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-knowledge-base.scss";
</style>
