<template>
  <b-card
    style="height: 90%"
    class="text-center"
  >
    <feather-icon
      :icon="icon"
      size="30"
    />
    <h5 class="mt-1">
      {{ title }}
    </h5>
    <p>{{ description }}</p>
    <b-button
      variant="outline-primary"
      @click="callback"
    >{{
      button
    }}</b-button>
  </b-card>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    button: {
      type: String,
      default: '',
    },
  },
  methods: {
    callback() {
      this.$emit('action')
    },
  },
}
</script>
